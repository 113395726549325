
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$WecooPortal-primary: mat.define-palette(mat.$indigo-palette);
$WecooPortal-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$WecooPortal-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$WecooPortal-theme: mat.define-light-theme((
  color: (
    primary: $WecooPortal-primary,
    accent: $WecooPortal-accent,
    warn: $WecooPortal-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($WecooPortal-theme);

/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "bootstrap/dist/css/bootstrap.min.css" layer(bootstrap);
// @import 'bootstrap/scss/bootstrap';
@import "bootstrap/dist/css/bootstrap.min.css" layer(bootstrap);
// @import 'bootstrap/scss/bootstrap';

@import 'assets/css/devices_css/devices.scss';

@import "@fancyapps/ui/dist/fancybox/fancybox.css";

@import "primeng/resources/primeng.css" layer(primeng);

@import "primeicons/primeicons.css";

.fancybox__container {
  z-index: 1056;
  --fancybox-bg: rgba(24, 24, 27, 0.9);
}

html,
body{
  height: 100%;
}
.fw-400{
  font-weight: 400;
}
.fw-600{
  font-weight: 600;
}
.fw-500{
  font-weight: 500;
}
body {
    margin : 0;
    font-family: sans-serif;
}
h1,h2,h3,h4,h5,p,span,b , td , tr , label , button{
  font-family: 'Montserrat', sans-serif;
}

html {
	.swal2-content {
		text-wrap: balance;
	}
}

.new-inner-body {
    img {
        max-width: 100%;
    }
}

.max-width-wrapper{
    max-width: 1200px;
    margin: auto;
    padding: 0 16px;
}

.content-wrapper {
    font-size: 16px;
    color: #3c3c3b;
    font-weight: 400;
    line-height: 22px;

    // display: grid;
    // grid-template-columns: 3fr 1fr;
    // grid-template-columns: 3fr 1fr;

    padding-bottom: 3em;

    min-height: calc(100% - 200px);
}

.content-wrapper.iframe {
  grid-template-columns: 1fr;

  .main-content {
    margin-right: 0;
    .container {
      max-width: 100%;
    }
  }
}

.lang-selector {
  span {
    color: #fff;
    padding-left: 6px;

    &.active {
      font-weight: bold;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

@media (max-width: 767px) {
    .content-wrapper {
        grid-template-columns: 1fr;
    }
}

@media (min-width: 767px) {
  .main-content {
    margin-right: 14px;
  }
}

.text-xs{
  font-size: 0.5em!important;
}
.text-s{
  font-size: 0.7em!important;
}
.text-m{
  font-size: 0.9em!important;
}
.text-l{
  font-size: 1.1em!important;
}
.text-xl{
  font-size: 1.3em!important;
}
.cursor-pointer{
  cursor: pointer;
}

html, body { height: 100%; }

body {
  font-family: "Montserrat", sans-serif;
  margin: 0;
}

.loading-screen {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  background: #454545;
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-dialog .p-dialog-header {
  //background: #f2f2f2;
  padding: 0.65rem;
  padding-left: 1rem;
}

p-calendar.size-s {
  .p-datepicker {
    border: none;
  }

  .p-datepicker-table {
    font-size: 15px;
  }

  .p-datepicker table td {
    padding: 0rem;
  }
}
.p-calendar .p-datepicker {
  min-width: 300px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.p-dialog-mask{
  z-index: 1000!important;
}
.p-dialog .p-dialog-content {
  //padding-bottom: 1.5rem;
  padding-bottom: 1.25rem;
  //padding-inline: 1rem;
}

.required_asterisk {
  color: #ec0000;
}
.form-inscription {
  .p-dropdown.p-component ,.p-inputtext  {
    width: 100%!important;
  }
  p-inputnumber , p-calendar{
    span{
      width: 100%!important;
    }
  }
}

p-dynamicDialog {
  .header-transparent {
    .p-dialog-header {
      position: absolute;
      right: 0;
      background: transparent;
      z-index: 5;
    }
    .p-dialog-content {
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;

      padding-top: 1.25rem;
    }
  }
}

.page-content {
  min-height: 60vh;
}
