// Reset
/*.device,
.device * {
  &,
  &::before,
  &::after {
    box-sizing: border-box;
    display: block;
  }
}*/

.device {
  position: relative;
  transform: scale(1);
  z-index: 1;

  .device-frame {
    z-index: 1;
  }

  .device-screen {
    background-color: #000;
    background-position: center center;
    background-size: cover;
    object-fit: cover;
    position: relative;
  }
}
